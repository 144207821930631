@import "./variables.module.scss";
.mainContainer {
  background-color: $background_base;
  @media screen and (max-width: 850px) {
    margin-left: 0;
  }
}

.nav {
  @media screen and (max-width: $small) {
    display: none;
  }
}
.phoneNav {
  @media screen and (min-width: $small) {
    display: none;
  }
}

.hamburger_top {
  height: 2px;
  width: 22px;
  background-color: $white;
  z-index: 40;
}

.hamburger_middle {
  height: 2px;
  width: 22px;
  background-color: $white;
}

.hamburger_bottom {
  height: 2px;
  width: 22px;
  background-color: $white;
}

@media (min-width: 765px) and (max-width: 1100px) {
  .modal {
    margin-left: 70px;
  }
}
