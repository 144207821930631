* {
  padding: 0px;
  margin: 0px;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}

body .sv-tagbox__item {
  height: auto;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

/* Loader */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(72, 63, 110);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Survey */
.nav-button {
  margin-left: auto;
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #ff9814;
}

.nav-input:hover {
  background-color: #f28a05;
}

body .sv-action-bar sv-action-bar--default-size-mode svc-page__footer {
  display: none;
}

body .svc-top-bar {
  display: none;
}

body .svc-test-tab__content-actions {
  display: none;
}

/* Map Popup */
.div.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

div.mapboxgl-popup-content {
  border-radius: 10px;
  padding: 12px;
}

.mapboxgl-popup-close-button {
  font-size: 15px;
  margin-right: 5px;
}

@media (max-width: 1980px) {
  div.Login_paper__BZeKx {
    max-width: 100vw;
  }
  .container {
    padding: 0;
  }
}

/* Survey */
.nav-button {
  margin-left: auto;
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #ff9814;
}

.nav-input:hover {
  background-color: #f28a05;
}

body .sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 0vw;
  outline: none;
  z-index: 2000;
  height: 0vh;
}

body .svc-designer__placeholder-container {
  min-height: 50vh;
}

#svd-settings {
  display: none;
}

#svd-grid-expand {
  display: none;
}

body .sv-action-bar .sv-action-bar--default-size-mode .svc-page__footer {
  display: none;
}

body .svc-toolbox {
  display: none;
}

body #duplicate {
  display: none;
}

body #convertTo {
  display: none;
}

body #delete {
  display: none;
}

body #isrequired {
  display: none;
}

body #settings {
  display: none;
}

body .svc-side-bar__container {
  min-width: 250px;
  width: 350px;
}

body .svc-designer-header {
  display: none;
}

body #sv-nav-complete {
  display: none;
}

body .svc-row {
  margin-bottom: 30px;
}

body .sd-title .sd-container-modern__title {
  display: none;
}

#scrollableDiv-test
  > div
  > div
  > div
  > div
  > div
  > div
  > form
  > div.sd-container-modern
  > div.sd-title.sd-container-modern__title {
  display: none;
}

body .svc-creator__content-wrapper {
  height: auto;
}

/* #wizard > div.MuiStack-root.css-o3b74m-MuiStack-root > div:nth-child(2) > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1ad7r4n-MuiPaper-root > div > div.MuiContainer-root.MuiContainer-maxWidthXl.Wizard_creatorContainer__EDlFu.css-19r6kue-MuiContainer-root > div > div > div > div.svc-flex-column.svc-flex-row__element.svc-flex-row__element--growing > div.svc-footer-bar > div > div */

#svd-designer {
  display: none;
}

#svd-preview {
  display: none;
}

#action-undo {
  display: none;
}

#action-redo {
  display: none;
}

/* .svc-string-editor > span {
  font-style: italic;
  color: grey;
} */

/* for option styling */
/* .svc-item-value__item .svc-string-editor {
  font-style: italic; 
  color: grey;
} */

/* body .sd-header__text{
  display: none;
}
body .sv_custom_header{
  display: none;
} */

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 512px) and (max-width: 768px) {
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }
  div.Login_paper__BZeKx {
    max-width: 70vw;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    margin-left: 50px;
    margin-right: 10px;
  }
  div.Login_paper__BZeKx {
    max-width: 50vw;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1600px) {
  .container {
    margin-left: 70px;
    margin-right: 10px;
  }
  div.Login_paper__BZeKx {
    max-width: 35vw;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1600px) {
  .container {
    margin-left: 60px;
    margin-right: 10px;
  }
  div.Login_paper__BZeKx {
    max-width: 25vw;
  }
}

/* Fade Transition */

.fade-enter div {
  overflow-y: hidden;
  max-height: 0;
}

.fade-enter-active div {
  max-height: 50px;
  transition: all 500ms ease-in;
}
.fade-exit div {
  max-height: 50px;
}
.fade-exit-active div {
  max-height: 0;
  overflow-y: hidden;
  transition: all 500ms ease-in;
}

/* Scroll bar */

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(31, 32, 63); /* #888; color of the scrollbar handle */
  border-top-right-radius: 8px; /* roundness of the scrollbar handle */
  border-bottom-right-radius: 8px;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(31, 32, 63, 0.1); /* color of the scrollbar track */
  border-radius: 0px;
}

/* For Firefox */
* {
  scrollbar-width: 12px;
  scrollbar-color: rgb(31, 32, 63);
}
