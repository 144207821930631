@import "./variables.module.scss";

.tableContainer {
  padding: $padding_main;
  position: relative;
  overflow-y: auto;

  .icon {
    color: inherit;

    &:hover {
      // color: $background_toolbar_secondary;
      background-color: $background_toolbar;
    }
  }

  .fade-enter {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  h2 {
    font-size: x-large;
    font-weight: 700;
    color: $text_color;
    border-right: 1px solid $background_toolbar_secondary;
    padding-right: 1rem;
  }
}
