@import "./variables.module.scss";

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // z-index: 1;
  // top: calc($layout_box_height - $table_toolbar_height);
  top: $padding_main;
}

.paper {
  .box {
    background-color: $color_primary_contrast;
    padding: $padding_secondary;
    position: relative;
    z-index: 2;
    flex-grow: 1;
  }

  h6 {
    // color: $color_primary;
    color: $text_color;
    font-weight: 900;
    border-right: 1px solid $background_toolbar_secondary;
    padding-right: 1rem;
  }

  .button {
    margin-top: 20px;
  }
}
