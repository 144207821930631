@import "./variables.module.scss";

.ACTIVE {
  background-color: $green;
}
.UPCOMING {
  background-color: $yellow;
}
.PAST {
  background-color: $redd;
}
.DRAFT {
  background-color: $purple;
}
.CANCELLED {
  background-color: $grey;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // top: $padding_main;
  // margin-top: $margin_main;
  padding-bottom: 20px;
  padding-top: $padTop;
}

.cardSingle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 525px;
  width: 475px;
  border-radius: 10px;
  margin: 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  transition: box-shadow 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  @media screen and (max-width: 650px) {
    height: 650px;
  }
  .cardHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cardHeaderIcon {
    background-color: #6365f17d;
    width: 38px;
    justify-content: center;
    align-items: center;
    height: 38px;
    border-radius: 20px;
    display: flex;
    img {
      border-radius: 50%;
      width: 38px;
      height: 38px;
    }
  }
  .cardHeaderTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    font-size: 1.25rem;
    color: text-color;

    h6 {
      cursor: pointer;
      font-size: 1.25rem;
      letter-spacing: 0.15px;
      font-weight: 400;
    }
    h6:hover {
      color: $color_secondary;
    }
  }
  .cardHeaderButton {
    padding: 5px;
  }
  .cardBodyContent {
    margin: 1.25rem 0px;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.15px;
    line-height: 1.5rem;
    color: rgba(58, 53, 65, 0.6);
    overflow: hidden;
  }
  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cardFooterIcons {
    display: flex;
    flex-direction: row-reverse;
  }
  .cardFooterIcon {
    border: 1px solid rgb(244, 245, 250);
    border-color: rgba(256, 256, 256, 0.8);
    background-color: $color_secondary;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(58, 58, 64, 0.42) 0px 4px 8px -4px;
      transform: translateY((-4px));
    }
  }
}
.cardFooterDates {
  display: flex;
  gap: 10px;
}
.cardFooterDate {
  background-color: rgba(136, 6, 6, 0.243);
  border-radius: 20px;
  padding: 5px;
  color: #f9f9f9;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: $margin_main;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-left: auto;
  margin-right: auto;

  .activeCard {
    display: inline-flex;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 38px;
    text-align: center;
    padding: 0.625rem;
    border-radius: 6px;
    text-align: left;
    color: $text_color;
    text-decoration: none;
    transition: color 0.15s ease, border-color 0.15s ease;
    background-color: $color_secondary;
    color: rgb(255, 255, 255);
    min-width: 130px;
    border: 0;

    p {
      text-transform: lowercase;
    }

    p::first-letter {
      text-transform: capitalize;
    }

    h3 {
      margin: 0 0 0rem 0;
      font-size: 1rem;
    }

    h2 {
      margin: 0 0 0rem 0;
      font-size: 1rem;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    p {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .disabled {
      pointer-events: none;
    }

    span {
      margin-top: 10px;
      color: $color_warn;
    }
  }

  .card {
    display: inline-flex;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 38px;
    text-align: center;
    padding: 0.625rem;
    border-radius: 6px;
    text-align: left;
    color: rgba(58, 53, 65, 0.6);
    transition: color 0.15s ease, border-color 0.15s ease;
    background-color: transparent;
    min-width: 130px;
    border: 0;
    p {
      text-transform: lowercase;
    }
    p::first-letter {
      text-transform: capitalize;
    }

    &:hover,
    &:focus,
    &:active {
      transition: all 0.5s ease-in-out;
      scale: 1.05;
      color: $color_secondary;
      border-color: $color_primary;
    }

    h3 {
      margin: 0 0 0rem 0;
      font-size: 1rem;
    }

    h2 {
      margin: 0 0 0rem 0;
      font-size: 1rem;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    p {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .disabled {
      pointer-events: none;
    }

    span {
      margin-top: 10px;
      // line-height: 40px;
      color: $color_warn;
    }
  }
}

.overlay {
  position: relative;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(197, 193, 193, 0.651);
}

@media (max-width: 599px) {
  .grid {
    transform: translateY(-30px);

    .activeCard,
    .card {
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      min-width: 60px;
    }
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .grid {
    transform: translateY(-20px);
  }
}
