@import "./variables.module.scss";

.grid {
  @media screen and (max-width: $small) {
    display: none !important;
  }
}

.expand {
  @media screen and (max-width: $small) {
    width: 100%;
  }
}
.paper {
  margin-bottom: $margin_main;
  @media screen and (max-width: $small) {
    width: 90%;
    padding: $padding_main;
    margin-top: 0;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    gap: 20px;
    justify-content: space-between;
    @media screen and (max-width: $small) {
      padding: $padding_secondary 5px;
    }

    h6 {
      font-weight: 900;
      color: $color_primary;
    }

    .form {
      .fields {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
      }
    }
  }
}
