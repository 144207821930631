@import "./variables.module.scss";

.wizardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  padding-top: $padTop;
}

.creatorContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: $padding_main;
  height: 100%;
  margin-bottom: 50px;
  --primary: $color_primary;
  --background: #ffffff;
  --background-dim: #f3f3f3;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #ffffff;
  --foreground: rgba(0, 0, 0, 0.87);
  --base-unit: 8px;
  @media screen and (max-width: 820px) {
    display: none;
  }
}
