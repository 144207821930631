/* Main styling */
$padding_main: 10px;
$margin_main: 50px;
$width_main: 60vw;
$white: rgb(255, 255, 255);

$padding_secondary: 80px;

/* Font */
$font_family: "Lato", "Ubuntu", sans-serif;
$font_weight: 600;
$font_weight_medium: 500;
$font_size_table: 14px;

/* Colours */
$color_primary: rgb(31, 32, 63);
$color_primary_lighter: rgba(90, 91, 112, 0.1);
$color_primary_hover: rgba(31, 32, 63, 0.2);
$color_primary_contrast: rgb(255, 255, 255);

$color_secondary: rgb(72, 63, 110);
$color_secondary_hover: rgb(72, 63, 110, 0.1);
$color_secondary_contrast: rgb(255, 255, 255);

$color_accent: rgb(255, 64, 129);
$color_accent_contrast: rgb(255, 255, 255);

$color_warn: #e71010f0; //rgb(244, 67, 54);
$color_warn_contrast: rgb(255, 255, 255);

$color_success: rgb(83, 141, 82);

$text_color: rgba(0, 0, 0, 0.87);
$text_color_light: white;
$text_secondary: rgba(0, 0, 0, 0.54);
$text_secondary_light: rgba(255, 255, 255, 0.7);
$text_hint: rgba(0, 0, 0, 0.38);
$text_hint_light: rgba(255, 255, 255, 0.5);

$background_base: rgb(242, 242, 247); //rgba(244, 245, 251, 255);
$background_foreground: white;
$background_toolbar: $color_primary_lighter; // #eeeef5;
$background_toolbar_secondary: #d8d8da;
$background_hover: rgba(0, 0, 0, 0.04);

//upcoming
$yellowLight: rgba(246, 202, 107, 0.2); //#f6ca6b26;
$yellow: rgb(249, 194, 75);
//active
$greenLight: rgba(72, 155, 164, 0.1); // #489ba41a;
$green: rgb(72, 155, 163);
//past
$redLight: rgb(255, 103, 110, 0.1); //#ff676e1a;
$redd: #ff676e;
//else
$purple: rgb(72, 63, 110);
$purpleLight: rgba(72, 63, 110, 0.2);

$grey: #3a42509d; //#3A42504D
$greyLight: #3a425014;
$color_secondary: rgb(105, 92, 163);

/* Transitions */
$trans_ease_in_out_duration: 0.5s;
$trans_ease_in_duration: 0.3s;
$trans_ease_out_duration: 0.4s;
$trans_shadow_duration: 0.28s;

$trans_ease_in_out_timing_function: cubic_bezier(0.35, 0, 0.25, 1);
$trans_ease_out_timing_function: cubic_bezier(0.25, 0.8, 0.25, 1);
$trans_ease_in_timing_function: cubic_bezier(0.55, 0, 0.55, 0.2);
$trans_shadow_timing_function: cubic_bezier(0.4, 0, 0.2, 1);

$trans_ease_in_out: all $trans_ease_in_out_duration
  $trans_ease_in_out_timing_function;
$trans_ease_out: all $trans_ease_out_duration $trans_ease_out_timing_function;
$trans_ease_in: all $trans_ease_in_duration $trans_ease_in_timing_function;

$trans_shadow: box-shadow $trans_shadow_duration $trans_shadow_timing_function;

/* Sidebar Nav */
$sidenav_width: 280px;
$sidenav_collapsed_width: 72px;

//green: rgb(120,208,177) yellow: rgb(224, 223, 192); //lighter shade: rgb(90,91,112)
$sidenav_background: rgb(90, 91, 112);
$sidenav_color: rgb(229, 226, 236);

$sidenav_toolbar_background: #171d29;
$sidenav_toolbar_background_rgb: 23, 29, 41;
$sidenav_section_divider_color: rgba(255, 255, 255, 0.12);

$sidenav_item_min_height: 48px;
$sidenav_item_background_hover: #141924;
$sidenav_item_background_active: #141924;
$sidenav_item_color: rgb(229, 226, 236); //#a1a2b6;
$sidenav_item_icon_color: rgb(229, 226, 236); //#999dd4;

$sidenav_subheading_color: #6b6e7f;

/* Toolbar Top */
$app_bar_height: 64px;
$app_bar_background: white;
$app_bar_color: rgba(0, 0, 0, 0.87);
$app_bar_icon_color: $color_primary;

/* Secondarty Toolbar? */
$secondary_toolbar_background: $background_foreground;
$secondary_toolbar_height: 54px;

/* Extra */
// $layout_box_height: 250px;

$navigation_height: 64px;
$navigation_background: $background_foreground;
$navigation_color: $text_secondary;

$page_layout_header_height: 200px;
$page_layout_toolbar_height: 64px;

$border_radius: 5px;

$padding_table: 0 16px 0 16px;
$table_toolbar_height: 64px;

$menu_item_br: 8px;
$menu_item_pd: 10px;

$card_width: 250px;
$card_height: 250px;

$padTop: 10rem;
/* Screens */

$small: 768px;
$medium: 992px;
$large: 1200px;

/* Export */

:export {
  font_family: $font_family;
  font_weight: $font_weight;
  font_weight_medium: $font_weight_medium;
  font_size_table: $font_size_table;

  background_base: $background_base;

  color_primary: $color_primary;
  color_primary_lighter: $color_primary_lighter;
  color_primary_hover: $color_primary_hover;
  color_primary_contrast: $color_primary_contrast;

  color_secondary: $color_primary;
  color_secondary_hover: $color_primary_hover;
  color_secondary_contrast: $color_primary_contrast;

  color_accent: $color_accent;
  color_warn: $color_warn;
  color_success: $color_success;

  text_color: $text_color;
  text_secondary: $text_secondary;
  text_hint: $text_hint;

  white: $white;

  yellowLight: $yellowLight;
  yellow: $yellow;

  greenLight: $greenLight;
  green: $green;

  redLight: $redLight;
  red: $redd;

  purple: $purple;
  purpleLight: $purpleLight;

  grey: $grey;
  greyLight: $greyLight;

  color_secondary: $color_secondary;

  // layout_box_height: $layout_box_height;

  padding_table: $padding_table;
  table_toolbar_height: $table_toolbar_height;

  background_toolbar: $background_toolbar;
  background_toolbar_secondary: $background_toolbar_secondary;

  sidenav_background: $sidenav_background;
  sidenav_item_icon_color: $sidenav_item_icon_color;

  border_radius: $border_radius;

  menu_item_br: $menu_item_br;
  menu_item_pd: $menu_item_pd;

  trans_ease_in_out: $trans_ease_in_out;

  padTop: $padTop;
}
